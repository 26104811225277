import UserRolesApi from 'api/UserRolesApi';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import MultiSelect from 'components/common/MultiSelect/MultiSelectV2';
import TextArea from 'components/common/TextArea';
import RepeatedInput from 'components/RepeatedInput';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DateTimePicker from 'components/common/DateTimePicker';
import _, { conformsTo } from 'lodash';
import moment from 'moment';
import { CameraIcon } from '@heroicons/react/solid';
import ProfileAPI from 'api/profileApi';
import StaffAPI from 'api/StaffManagementApi';
import classNames from 'classnames';
import Label from 'components/common/Label';
import { MAX_LENGTH_SHORT_BIO } from 'utils/constants';
export interface PhoneProps {
  countryCode: string;
  phoneNumber: string;
}

export interface userTypeProps {
  userRoleId: string;
  firstName: string;
  lastName: string;
  prefix: string;
  displayName: string;
  gender: string;
  dob: string;
  email: string;
  phone: PhoneProps;
  npi: string;
  timezone?: string;
  activeStates: string[] | string;
  specialtyAreas: string[] | string;
  aboutDoctor?: string;
  aboutNursePractitioner?: string;
  experience: string[] | string;
  status: string;
  userCostId: string;
  oldPassword: any;
  newPassword: any;
  payAmount?: number;
  payType?: '';
  specialization: string;
}
export interface FormProps {
  handleFormSubmit?: ({ data }: any) => void;
  handleEditedFormSubmit?: (data: any, id: string) => void;
  handleDelete?: (id: string) => void;
  data?: any;
  isEdit?: any;
}

const Form = ({ data, setShowModal, isEdit, userImage, userIdSetter, fetchProfileData }: any) => {
  const payload: any = {
    userRoleId: '',
    firstName: '',
    lastName: '',
    prefix: '',
    displayName: '',
    gender: '',
    dob: '',
    email: '',
    phone: { countryCode: 'US', phoneNumber: '' },
    npi: '',
    activeStates: [],
    specialtyAreas: [],
    experience: [],
    status: '',
    userCostId: '',
    payAmount: '',
    payType: '',
    oldPassword: '',
    newPassword: '',
    specialization: '',
  };
  const [userData, setUserData] = useState<userTypeProps | any>(payload);
  const [userRole, setUserRole] = useState([]);
  const [userRoleValue, setUserRoleValue] = useState('');
  const [userType, setUserType] = useState([]);
  const [userTypeName, setUserTypeName] = useState<string>();
  const [payTypeData, setPayTypeData] = useState([{ value: '', label: '' }]);
  const [payType, setPayType] = useState<IOBJ[]>();
  const [creationDate, setCreationDate] = useState('');
  const [exp, setExp] = useState([]);

  const [specialityArea, setSpecialityArea] = useState([]);
  const [specialityAreaData, setSpecialityAreaData] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [specializationData, setSpecializationData] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [timeZoneData, setTimeZoneData] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [timeZoneValue, setTimeZoneValue] = useState<IOBJ>({ value: '', label: '' });

  const [stateList, setStateList] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [stateListData, setStateListData] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [timezone, setTimeZone] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [biography, setBiography] = useState('');
  const [shortBiography, setShortBiography] = useState('');
  const [roleOptions, setRoleOptions] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [userId, setUserId] = useState('');
  const [test, setTest] = useState<any>([]);
  const [passwordStrength, setPasswordStrength] = useState<any>(false);
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [notformatNumber, setNotformatNumber] = useState<any>();
  const [statesSet, setStatesSet] = useState(false);
  const [notify, setNotify] = useState(false);
  const [patientAssignmentEmail, setpatientAssignmentEmail] = useState(false);
  const [appointmentCreationEmail, setappointmentCreationEmail] = useState(false);
  const [appointmentCancelationEmail, setappointmentCancelationEmail] = useState(false);
  const [appointmentUpdationEmail, setappointmentUpdationEmail] = useState(false);
  const [appointmentReminderFirstEmail, setappointmentReminderFirstEmail] = useState(false);
  const [appointmentReminderSecondEmail, setappointmentReminderSecondEmail] = useState(false);
  const [patientAssignmentSms, setpatientAssignmentSms] = useState(false);
  const [appointmentCreationSms, setappointmentCreationSms] = useState(false);
  const [appointmentCancelationSms, setappointmentCancelationSms] = useState(false);
  const [appointmentUpdationSms, setappointmentUpdationSms] = useState(false);
  const [appointmentReminderFirstSms, setappointmentReminderFirstSms] = useState(false);
  const [appointmentReminderSecondSms, setappointmentReminderSecondSms] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const isPhysician = userTypeName === 'Physician';

  type IOBJ = {
    label: string;
    value: string;
  };
  const options4 = [
    { value: '0', label: 'Male' },
    { value: '1', label: 'Female' },
    { value: '2', label: 'Other' },
    { value: '3', label: 'Unknown' },
  ];

  const passwordValidation = (data: any) => {
    {
      setPasswordStrength(false);
      const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
      if (strongRegex.test(data)) {
        setPasswordStrength(true);
      }
    }
  };

  useEffect(() => {
    UserRolesApi.fetchUser({}).then((res) => {
      setUserRole(res?.data?.data);
    });
  }, [userTypeName]);
  useEffect(() => {
    if (!_.isEmpty(data?.[0])) {
      const eachuser = data?.[0];
      let activeStateCopy: any = [];
      let spArea: any = [];
      setExp(eachuser?.experience?.filter((x: any) => x !== ''));

      spArea = eachuser?.specialtyAreasInfo?.map((item: any) => {
        return { value: item._id, label: item.name };
      });
      activeStateCopy = eachuser?.activeStates?.map((item: any) => {
        return { value: item, label: item };
      });
      setUserRoleValue(eachuser?.userRoleInfo?.name);
      if (eachuser?.aboutNursePractitioner) {
        setBiography(eachuser?.aboutNursePractitioner);
      }
      if (eachuser?.aboutDoctor) {
        setBiography(eachuser?.aboutDoctor);
      }
      if (eachuser?.shortBio) {
        setShortBiography(eachuser?.shortBio);
      }
      const phonenum = formatPhoneNumber(eachuser.phone.phoneNumber);
      setPhoneNumber(phonenum);
      setCreationDate(moment.parseZone(eachuser?.dob).format('YYYY-MM-DD'));
      const editPayload: any = {
        userRoleId: eachuser?.userRoleInfo?._id,
        firstName: eachuser?.firstName,
        lastName: eachuser?.lastName,
        prefix: eachuser?.prefix ?? '',
        displayName: eachuser?.displayName,
        gender: eachuser?.gender,
        dob: moment.parseZone(eachuser?.dob).format('YYYY-MM-DD'),
        email: eachuser?.email,
        phone: {
          countryCode: eachuser?.phone?.countryCode ? eachuser?.phone?.countryCode : 'US',
          phoneNumber: eachuser?.phone?.phoneNumber,
        },
        npi: eachuser?.npi,
        specialization: eachuser?.specialization,
        activeStates: activeStateCopy,
        specialtyAreas: spArea,
        ...(eachuser?.aboutDoctor && { aboutDoctor: eachuser?.aboutDoctor }),
        ...(eachuser?.aboutNursePractitioner && { aboutNursePractitioner: eachuser?.aboutNursePractitioner }),
        experience: eachuser?.experience,
        status: eachuser?.status,
        userCostId: eachuser?.staffCostInfo?._id,
        payType: eachuser?.staffCostInfo?.payType,
        payAmount: eachuser?.staffCostInfo?.payAmount,
        notificationPreferences: eachuser?.notificationPreferences,
      };
      if (userData?.timezone) {
        editPayload['timezone'] = userData?.timezone;
      }
      if (eachuser?.notificationPreferences) {
        const { emailNotification } = eachuser?.notificationPreferences;
        const { textNotification } = eachuser?.notificationPreferences;
        setNotify(eachuser?.notificationPreferences?.notifyWhenOnline);
        setpatientAssignmentEmail(emailNotification?.patientAssignment);
        setpatientAssignmentSms(textNotification?.patientAssignment);
        setappointmentCreationEmail(emailNotification?.appointmentCreation);
        setappointmentCreationSms(textNotification?.appointmentCreation);
        setappointmentCancelationEmail(emailNotification?.appointmentCancelation);
        setappointmentCancelationSms(textNotification?.appointmentCancelation);
        setappointmentUpdationEmail(emailNotification?.appointmentUpdation);
        setappointmentUpdationSms(textNotification?.appointmentUpdation);
        setappointmentReminderFirstEmail(emailNotification?.appointmentReminderFirst);
        setappointmentReminderFirstSms(textNotification?.appointmentReminderFirst);
        setappointmentReminderSecondEmail(emailNotification?.appointmentReminderSecond);
        setappointmentReminderSecondSms(textNotification?.appointmentReminderSecond);
      }
      setUserId(eachuser?.userId);
      setTest(editPayload);
      setUserData(editPayload);
    }
  }, [data]);

  useEffect(() => {
    if (timezone && userData?.userRoleId && !_.isEmpty(data?.[0])) {
      const userDetails = data?.[0];
      const userDataValue = { ...userData };
      const userTimeZoneValue = timezone.find((item) => item.value === userDetails?.timezone);
      userDataValue['timezone'] = userTimeZoneValue;
      setUserData(userDataValue);
    }
  }, [timezone]);

  // use effect for api call lookups
  useEffect(() => {
    StaffAPI.specialialityAreasLookup({}).then((res) => {
      setSpecialityArea(res?.data?.data);
      CustomDataMapper(res?.data?.data, 'specialityArea');
    });
    StaffAPI.stateListLookup({}).then((res) => {
      setStateList(res?.data?.data);
      CustomDataMapper(res?.data?.data, 'stateList');
    });
    StaffAPI.timezoneLookup({}).then((res) => {
      setTimeZone(res?.data?.data);
      CustomDataMapper(res?.data?.data, 'timezone');
    });
  }, []);

  const onChangeNotification = (data?: any, label?: any) => {
    const key = Object.keys(data)[0];
    const notificationData = {
      notifyWhenOnline: notify,
      emailNotification: {
        patientAssignment: patientAssignmentEmail,
        appointmentCreation: appointmentCreationEmail,
        appointmentCancelation: appointmentCancelationEmail,
        appointmentUpdation: appointmentUpdationEmail,
        appointmentReminderFirst: appointmentReminderFirstEmail,
        appointmentReminderSecond: appointmentReminderSecondEmail,
      },
      textNotification: {
        patientAssignment: patientAssignmentSms,
        appointmentCreation: appointmentCreationSms,
        appointmentCancelation: appointmentCancelationSms,
        appointmentUpdation: appointmentUpdationSms,
        appointmentReminderFirst: appointmentReminderFirstSms,
        appointmentReminderSecond: appointmentReminderSecondSms,
      },
    };
    if (key === 'emailNotification') {
      if (label === 'New patient assigned to me') {
        notificationData.emailNotification.patientAssignment = !patientAssignmentEmail;
        setpatientAssignmentEmail(!patientAssignmentEmail);
      } else if (label === 'New appointment created') {
        notificationData.emailNotification.appointmentCreation = !appointmentCreationEmail;
        setappointmentCreationEmail(!appointmentCreationEmail);
      } else if (label === 'Appointment cancelled') {
        notificationData.emailNotification.appointmentCancelation = !appointmentCancelationEmail;
        setappointmentCancelationEmail(!appointmentCancelationEmail);
      } else if (label === 'Appointment updated') {
        notificationData.emailNotification.appointmentUpdation = !appointmentUpdationEmail;
        setappointmentUpdationEmail(!appointmentUpdationEmail);
      } else if (label === 'Appointment begins in 5 minutes') {
        notificationData.emailNotification.appointmentReminderFirst = !appointmentReminderFirstEmail;
        setappointmentReminderFirstEmail(!appointmentReminderFirstEmail);
      } else if (label === 'Appointment begins in 30 minutes') {
        notificationData.emailNotification.appointmentReminderSecond = !appointmentReminderSecondEmail;
        setappointmentReminderSecondEmail(!appointmentReminderSecondEmail);
      }
    } else if (key === 'textNotification') {
      if (label === 'New patient assigned to me') {
        notificationData.textNotification.patientAssignment = !patientAssignmentSms;
        setpatientAssignmentSms(!patientAssignmentSms);
      } else if (label === 'New appointment created') {
        notificationData.textNotification.appointmentCreation = !appointmentCreationSms;
        setappointmentCreationSms(!appointmentCreationSms);
      } else if (label === 'Appointment cancelled') {
        notificationData.textNotification.appointmentCancelation = !appointmentCancelationSms;
        setappointmentCancelationSms(!appointmentCancelationSms);
      } else if (label === 'Appointment updated') {
        notificationData.textNotification.appointmentUpdation = !appointmentUpdationSms;
        setappointmentUpdationSms(!appointmentUpdationSms);
      } else if (label === 'Appointment begins in 5 minutes') {
        notificationData.textNotification.appointmentReminderFirst = !appointmentReminderFirstSms;
        setappointmentReminderFirstSms(!appointmentReminderFirstSms);
      } else if (label === 'Appointment begins in 30 minutes') {
        const { textNotification } = data;
        setappointmentReminderSecondSms(textNotification);
        notificationData.textNotification.appointmentReminderSecond = textNotification;
      }
    } else if (key === 'notifyWhenOnline') {
      const { notifyWhenOnline } = data;
      setNotify(notifyWhenOnline);
      notificationData.notifyWhenOnline = notifyWhenOnline;
    }
    onSelection({ notificationPreferences: notificationData });
  };

  // SUBMIT FUNCTION

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setDisabledSubmit(true);
    const addedAboutDocKey: any = { ...(isPhysician && { aboutDoctor: biography }) };
    const addedAboutNurseKey: any = {
      ...(userTypeName == 'Nurse Practitioner' && { aboutNursePractitioner: biography }),
    };

    const activeStateupdate = userData?.activeStates;
    const specialtyAreasUpdated = userData?.specialtyAreas;

    const data: any = [];
    const dataSpArea: any = [];
    activeStateupdate.map((item: any) => {
      data.push(item.value);
    });
    specialtyAreasUpdated.map((item: any) => {
      dataSpArea.push(item.value);
    });
    const stateCopy = { ...userData };
    stateCopy['activeStates'] = data;
    stateCopy['timezone'] = userData?.timezone?.value;
    stateCopy['dob'] = creationDate && moment(creationDate).format('YYYY-MM-DD');
    stateCopy['phone'] = {
      countryCode: 'US',
      phoneNumber: userData?.phone?.phoneNumber?.replace(/[- )(]/g, '').slice(0, 10),
    };
    stateCopy['specialtyAreas'] = dataSpArea;
    stateCopy['experience'] = exp;
    stateCopy['notificationPreferences'] = userData?.notificationPreferences;
    if (addedAboutDocKey) {
      stateCopy['aboutDoctor'] = addedAboutDocKey?.aboutDoctor;
      stateCopy['shortBio'] = shortBiography;
    }
    if (addedAboutNurseKey) {
      stateCopy['aboutNursePractitioner'] = addedAboutNurseKey?.aboutNursePractitioner;
    }

    submitApi(stateCopy);
  };

  const submitApi = (data: any) => {
    ProfileAPI.editProfile(data)
      .then((res: any) => {
        if (res.data.errorCode == 0) {
          toast.success('Successfully edited');
          userImage &&
            ProfileAPI.postProfileImage(userImage)
              .then((res: any) => {
                if (res?.data?.errorCode == 0) {
                  fetchProfileData();
                }
              })
              .catch((err) => {
                toast.error(err?.response?.data?.message);
              });
          fetchProfileData();
          setShowModal?.(false);
        }
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => setDisabledSubmit(false));
  };

  // custom data mapper for dropsDowns to change to {value: '' ,label:''} format
  const CustomDataMapper = (input: any, key: any) => {
    const data: IOBJ[] = [];
    input.map((item: any) => {
      if (key === 'timezone') data.push({ value: item?.value, label: item?.label });
      else if (key === 'payType') data.push({ value: item?._id, label: item?.payType });
      else if (key === 'specialityArea' || key === 'specialization') data.push({ value: item?._id, label: item?.name });
      else data.push({ value: item?.name, label: item?.name });
    });
    if (key === 'specialization') setSpecializationData(data);
    if (key === 'timezone') setTimeZoneData(data);
    if (key === 'specialityArea') setSpecialityAreaData(data);
    if (key === 'payType') setPayTypeData(data);
    if (key === 'stateList') {
      setStateListData(data);
      setStatesSet(true);
    }
  };

  // DROPDOWN FOR USER ROLE OPTIONS DATA MAP
  useEffect(() => {
    const data: IOBJ[] = [];
    const userTypedata: any = [];
    userRole.map((user: any) => {
      data.push({ value: user._id, label: user.name });
      userTypedata.push(user.userType);
    });
    setRoleOptions(data);
    setUserType(userTypedata);
  }, [userRole]);

  useEffect(() => {
    userRole.map((role: any) => {
      {
        if (role._id === userData?.userRoleId) {
          setUserTypeName(role?.userType?.name);
          setUserRoleValue(role.name);
        }
      }
    });
  }, [userData, userRole]);

  // Handler for normal input
  const handleChanges = (e: any) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
    if (e.target.name == 'newPassword') {
      passwordValidation(e.target.value);
    }
  };

  // handler for multiselect
  const multiselectHandler = (e: any, fieldName: any) => {
    const data: any = [];
    e.map((item: any) => {
      if (fieldName === 'activeStates') data.push({ value: item.value, label: item.label });
      else data.push({ value: item.value, label: item.label });
    });
    setUserData({ ...userData, [fieldName]: data });
  };

  // handler for repeated input
  const repeatedInputChange = (data: any, index: any) => {
    const item: any = [...exp];
    item[index] = data?.target?.value;
    const newdata = item.filter((x: any) => x !== undefined);
    setExp(Object.values?.(newdata));
  };

  const handleAdditon = () => {
    const expCopy: any = [...exp];
    expCopy.push('');
    setExp(expCopy);
  };
  // code for normal dropdown selection & unselection
  const onSelection = (e: any) => {
    const key = Object.keys(e)[0];
    const currentState = { ...userData };
    if (key == 'payType') {
      currentState[key] = e[key].label;
      currentState.userCostId = e.payType.value;
      payType?.map((item: any) => {
        if (item._id === e.payType.value) {
          currentState.payAmount = item.payAmount;
        }
      });
    } else if (key === 'timezone') {
      currentState[key] = e[key];
    } else if (key === 'notificationPreferences') {
      currentState[key] = e[key];
    } else if (key === 'gender') {
      currentState[key] = e[key].value;
    } else {
      currentState[key] = e[key].value;
    }
    setUserData(currentState);
  };
  const handleBio = (e: any) => {
    setBiography(e.target.value);
  };

  const handleShortBio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value.length <= MAX_LENGTH_SHORT_BIO) {
      setShortBiography(e.target.value);
    }
  };

  function formatPhoneNumber(value: any) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  const handlePhone = (e: any) => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    // setInputValue(formattedPhoneNumber);
    setUserData({ ...userData, phone: { ...userData.phone, phoneNumber: e.target.value } });
    setPhoneNumber(formattedPhoneNumber);
    setNotformatNumber(e.target.value);
  };

  return (
    <>
      <form className="mt-10" onSubmit={handleSubmit}>
        <div className="flex justify-between items-start">
          <div className="mr-4">
            <Input
              labelText="First name"
              labelClasses="w-20 mr-20 text-medium"
              inputClasses="w-52"
              wrapperClasses="mb-4"
              onChange={handleChanges}
              name="firstName"
              value={userData?.firstName}
            />
            <Input
              labelText="Prefix"
              labelClasses="w-20 mr-20 text-medium"
              inputClasses="w-52"
              wrapperClasses="mb-4"
              onChange={handleChanges}
              name="prefix"
              value={userData?.prefix ?? ''}
              required={false}
            />

            <Dropdown
              labelText="Sex at birth"
              labelClasses="w-20 mr-20 text-medium "
              width="52"
              options={options4}
              onChange={(e: any) => onSelection({ gender: e })}
              value={{
                value: userData?.gender || '',
                label: options4.find((el) => el.value === userData?.gender)?.label || '',
              }}
            />

            <Input
              labelText="Email"
              labelClasses="w-20 mr-20 text-medium"
              inputClasses="w-52"
              wrapperClasses="mb-4"
              onChange={handleChanges}
              name="email"
              value={userData?.email}
            />
            <Input
              labelText="Old password"
              labelClasses="w-20 mr-14 text-medium"
              inputClasses="w-52"
              wrapperClasses="mb-4"
              onChange={handleChanges}
              name="oldPassword"
              value={userData?.oldPassword ? userData?.oldPassword : ''}
              type="password"
              required={userData?.newPassword ? true : false}
              autoComplete="new-password"
            />
          </div>
          <div className="relative">
            <Input
              labelText="Last name"
              labelClasses="w-20 mr-12 text-medium"
              inputClasses="w-52"
              wrapperClasses="mb-4"
              onChange={handleChanges}
              name="lastName"
              value={userData?.lastName}
            />
            <Input
              labelText="Display name"
              labelClasses="w-36 mr-2 text-medium"
              inputClasses="w-52"
              wrapperClasses="mb-4"
              onChange={handleChanges}
              name="displayName"
              value={userData?.displayName}
            />
            <DateTimePicker
              placeholder="Select date"
              name="Birthdate"
              labelText="Birthdate"
              inputClasses="w-52 mb-4 text-sm relative w-full"
              labelClasses="w-36 text-medium	"
              format="MM-DD-YYYY"
              value={creationDate && moment(creationDate).format('MM-DD-YYYY')}
              onChange={(e) => setCreationDate(e)}
            />

            <Input
              labelText="Phone"
              labelClasses="w-20 mr-12 text-medium"
              inputClasses="w-52"
              wrapperClasses="mb-4"
              onChange={(e) => {
                handlePhone(e);
              }}
              name="phoneNumber"
              value={phoneNumber}
            />

            <Input
              labelText="New password"
              labelClasses="w-28 mr-6 text-medium"
              inputClasses="w-52"
              wrapperClasses="mb-4  "
              onChange={handleChanges}
              name="newPassword"
              value={userData?.newPassword ? userData?.newPassword : ''}
              type="password"
              required={false}
            />
            {userData && userData.newPassword && (
              <span
                className={classNames(
                  ' text-sm  ml-4 absolute right-0 font-bold	',
                  passwordStrength ? 'text-green' : 'text-red ',
                )}
              >
                {passwordStrength && passwordStrength ? 'strong' : 'weak'}
              </span>
            )}
          </div>
        </div>

        {(userTypeName === 'Nurse Practitioner' || userTypeName === 'Physician') && (
          <>
            <div className="flex flex-col lg:flex-row lg:justify-between items-center ">
              <Input
                labelText="NPI #"
                labelClasses="w-20 mr-20 text-medium"
                inputClasses="w-52"
                wrapperClasses="mb-4"
                onChange={handleChanges}
                name="npi"
                value={userData?.npi}
                disabled={true}
              />
            </div>
            <div className="mb-4">
              <Dropdown
                labelText="TimeZone"
                labelClasses="w-24 mr-12 text-medium adminlabelstaff"
                width="full"
                options={timeZoneData}
                onChange={(e: any) => onSelection({ timezone: e })}
                value={userData?.timezone}
                // onClickIconClear={() => onSelection({ timezone: null })}
              />
            </div>
            {/* <div className="mb-4">
              <Input
                labelText="Specialization"
                labelClasses="w-28 mr-8 text-medium"
                inputClasses="w-full"
                wrapperClasses="mb-4"
                onChange={handleChanges}
                name="specialization"
                value={userData?.specialization}
              />
            </div> */}

            <MultiSelect
              labelText="Licensed in"
              labelClasses="w-28 mr-10 text-medium"
              options={stateListData}
              value={statesSet ? userData?.activeStates : null}
              handleChange={(data: any) => multiselectHandler(data, 'activeStates')}
              field="states"
              statesSet={statesSet}
            />
            <MultiSelect
              labelText="Specialty areas"
              labelClasses="w-36 mr-4 text-medium"
              options={specialityAreaData}
              value={userData?.specialtyAreas}
              handleChange={(data: any) => multiselectHandler(data, 'specialtyAreas')}
              field="speciality areas"
            />
            {isPhysician && (
              <TextArea
                labelClasses="w-20 mr-14 text-medium adminstafftext"
                labelText="Short biography"
                handleChange={handleShortBio}
                name="Short biography"
                required={false}
                hintElement={'Limit 500 characters.'}
                value={shortBiography}
                rows={2}
              />
            )}
            <TextArea
              labelClasses="w-20 mr-14 text-medium adminstafftext"
              labelText={isPhysician ? 'Long biography' : 'Biography'}
              handleChange={handleBio}
              name="Biography"
              hintElement={
                <>
                  <span className="font-bold">{'*bold* _italic_ '}</span>
                  {'[Link text](https://linkurl.com)'}
                </>
              }
              value={biography}
              rows={4}
            />
            <RepeatedInput
              labelClasses="w-20 mr-14 text-medium"
              labelText="Education"
              onHandleChange={repeatedInputChange}
              value={exp}
              handleAdditon={handleAdditon}
            />
          </>
        )}
        {isPhysician && (
          <>
            <div>
              <div className="bg-lightGreyBackground my-8 h-1" />
              <div className="mb-4 flex items-center">
                <Label labelText="Notifications" labelClasses="w-3/4 mr-12 text-lg" />
                <div className="flex">
                  <Label labelText="Email" labelClasses="text-medium float-left ml-6" />
                  <Label labelText="SMS" labelClasses="text-medium float-left ml-4" />
                </div>
              </div>
              <div className="mb-4 flex-col items-center">
                <div className="mb-4 flex items-center">
                  <p className="w-3/4 mr-14 text-medium">New patient assigned to me</p>
                  <div className="flex">
                    <div className="form-check notification-checkbox ml-6">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        checked={patientAssignmentEmail}
                        onChange={() =>
                          onChangeNotification(
                            { emailNotification: !patientAssignmentEmail },
                            'New patient assigned to me',
                          )
                        }
                      />
                    </div>
                    <div className="form-check ml-8 notification-checkbox">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        checked={patientAssignmentSms}
                        onChange={() =>
                          onChangeNotification(
                            { textNotification: !patientAssignmentSms },
                            'New patient assigned to me',
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 flex-col items-center">
                <div className="mb-4 flex items-center">
                  <p className="w-3/4 mr-14 text-medium">New appointment created</p>
                  <div className="flex">
                    <div className="form-check notification-checkbox ml-6">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        checked={appointmentCreationEmail}
                        onChange={() =>
                          onChangeNotification(
                            { emailNotification: !appointmentCreationEmail },
                            'New appointment created',
                          )
                        }
                      />
                    </div>
                    <div className="form-check ml-8 notification-checkbox">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        checked={appointmentCreationSms}
                        onChange={() =>
                          onChangeNotification({ textNotification: !appointmentCreationSms }, 'New appointment created')
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 flex-col items-center">
                <div className="mb-4 flex items-center">
                  <p className="w-3/4 mr-14 text-medium">Appointment cancelled</p>
                  <div className="flex">
                    <div className="form-check notification-checkbox ml-6">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        checked={appointmentCancelationEmail}
                        onChange={() =>
                          onChangeNotification(
                            { emailNotification: !appointmentCancelationEmail },
                            'Appointment cancelled',
                          )
                        }
                      />
                    </div>
                    <div className="form-check ml-8 notification-checkbox">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        checked={appointmentCancelationSms}
                        onChange={() =>
                          onChangeNotification(
                            { textNotification: !appointmentCancelationSms },
                            'Appointment cancelled',
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 flex-col items-center">
                <div className="mb-4 flex items-center">
                  <p className="w-3/4 mr-14 text-medium">Appointment updated</p>
                  <div className="flex">
                    <div className="form-check notification-checkbox ml-6">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        checked={appointmentUpdationEmail}
                        onChange={() =>
                          onChangeNotification({ emailNotification: !appointmentUpdationEmail }, 'Appointment updated')
                        }
                      />
                    </div>
                    <div className="form-check ml-8 notification-checkbox">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        checked={appointmentUpdationSms}
                        onChange={() =>
                          onChangeNotification({ textNotification: !appointmentUpdationSms }, 'Appointment updated')
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 flex-col items-center">
                <div className="mb-4 flex items-center">
                  <p className="w-3/4 mr-14 text-medium">Appointment begins in 5 minutes</p>
                  <div className="flex">
                    <div className="form-check notification-checkbox ml-6">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        checked={appointmentReminderFirstEmail}
                        onChange={() =>
                          onChangeNotification(
                            { emailNotification: !appointmentReminderFirstEmail },
                            'Appointment begins in 5 minutes',
                          )
                        }
                      />
                    </div>
                    <div className="form-check ml-8 notification-checkbox">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        checked={appointmentReminderFirstSms}
                        onChange={() =>
                          onChangeNotification(
                            { textNotification: !appointmentReminderFirstSms },
                            'Appointment begins in 5 minutes',
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 flex-col items-center">
                <div className="mb-4 flex items-center">
                  <p className="w-3/4 mr-14 text-medium">Appointment begins in 30 minutes</p>
                  <div className="flex">
                    <div className="form-check notification-checkbox ml-6">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        checked={appointmentReminderSecondEmail}
                        onChange={() =>
                          onChangeNotification(
                            { emailNotification: !appointmentReminderSecondEmail },
                            'Appointment begins in 30 minutes',
                          )
                        }
                      />
                    </div>
                    <div className="form-check ml-8 notification-checkbox">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        checked={appointmentReminderSecondSms}
                        onChange={() =>
                          onChangeNotification(
                            { textNotification: !appointmentReminderSecondSms },
                            'Appointment begins in 30 minutes',
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-12 flex items-center bg-lightGreyBackground">
                <div className="ml-5 form-check notification-checkbox">
                  <input
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    checked={notify}
                    onChange={() =>
                      onChangeNotification({
                        notifyWhenOnline: !notify,
                      })
                    }
                  />
                </div>
                <div>
                  <Label
                    labelText="Send SMS and Email notifications when I'm actively working in the LifeMD system"
                    labelClasses="w-3/4 mr-12 text-medium"
                  />
                </div>
              </div>
              <div className="bg-lightGreyBackground  h-1 my-8" />
            </div>
          </>
        )}
        <div className="flex items-center justify-between mt-8 pt-4 pb-2 ">
          <Button ownClasses="rounded-full absolute right-0 px-8" type="success" disabled={disabledSubmit} formType>
            Save
          </Button>
        </div>
      </form>
    </>
  );
};
export default Form;
