import Button from '../common/Button';
import Table from '../common/Table';
import SearchForm from './SearchForm';
import { ReactComponent as PlusSvg } from '../../assets/icons/lifeMD-plus.svg';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import { useEffect, useRef, useState } from 'react';
import Modal from 'components/AdminRequestModal';
import Footer from '../AdminRequestModal/Footer';
import Form from '../AdminRequestModal/Form';
import Links from '../AdminRequestModal/Links';
import StandartHeader from '../AdminRequestModal/Headers/StandartHeader';
import { RootStateOrAny, useSelector } from 'react-redux';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { CheckCategoryColor } from 'utils/CheckCategoryColor';
import { toast } from 'react-toastify';
import RequestTypeAPI from 'api/RequestTypeAPI';

export interface RequestListProps {
  type: string;
  fetchRequestType: (value?: any) => void;
  loading: boolean;
}

const StandardTable: React.FC<RequestListProps> = ({ type, fetchRequestType, loading }) => {
  const requestTypeList = useSelector((state: RootStateOrAny) =>
    type === 'physician' ? state.requestType.physicianRequestTypeList : state.requestType.frontDeskRequestTypeList,
  );
  const searchRef = useRef<any>();
  const [requestType, setRequestType] = useState<any>();
  const [inlineFilterActive, setInlineFilterActive] = useState('All');
  const [showPopover, setShowPopover] = useState(false);
  const [selectedTask, setSelectedTask] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchFilterParams, setSearchFilterParams] = useState({});
  const [pageItems, setPageItems] = useState([]);
  const [sortKey, setSortKey] = useState<string | undefined>('');
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [params, setParams] = useState<any>();

  const totalRequestCount = useSelector((state: RootStateOrAny) =>
    type === 'physician'
      ? state.requestType.physicianRequestTypeTotalCount
      : state.requestType.frontDeskRequestTypeTotalCount,
  );
  const totalPage =
    totalRequestCount % 10 === 0 ? Math.floor(totalRequestCount / 10) : Math.floor(totalRequestCount / 10) + 1;
  const headerItemsPhysicianRequestType = [
    {
      title: 'Category',
      sortKey: 'category',
    },
    {
      title: 'Request',
      sortKey: 'request',
    },
    {
      title: 'Route to',
      sortKey: 'routeTo',
    },
    {
      title: 'Automations',
      sortKey: 'triggers',
    },
    {
      title: 'Unique id',
      sortKey: 'uniqueId ',
    },
    {
      title: 'Status',
      sortKey: 'status',
    },
    {
      title: 'Actions',
      align: 'center',
    },
  ];

  const headerItemsFrontDeskRequestType = [
    {
      title: 'Request',
      sortKey: 'requestType',
    },
    {
      title: 'Route to',
      sortKey: 'routeTo',
    },
    {
      title: 'Triggers',
      sortKey: 'triggers',
    },
    {
      title: 'Unique id',
      sortKey: 'uniqueId',
    },
    {
      title: 'Status',
      sortKey: 'status',
    },
    {
      title: 'Actions',
      align: 'center',
    },
  ];
  const [formData, setFormData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [requestTypeId, setRequestTypeId] = useState('');
  const [showModal, setShowModal] = useState(false);

  const onSortClick = (title?: string, key?: string, sortOrder?: string) => {
    setSelectedPage(1);
    setSortKey(key);
    setSortOrder(sortOrder);
    const filterParams: any = {
      ...searchFilterParams,
      ...(key && { sortField: key }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    fetchRequestType(filterParams);
    setParams(filterParams);
  };

  const inlineStatusHandler = (inlineCategoryValue: string) => {
    setInlineFilterActive(inlineCategoryValue);
    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = 0;
    filterParams['status'] = inlineCategoryValue !== 'All' ? inlineCategoryValue : null;

    setSelectedPage(1);
    fetchRequestType(filterParams);
    setParams(filterParams);
  };

  const filterSearchTextHandler = (searchTextFilterValue?: any) => {
    setSearchFilterParams(searchTextFilterValue);
    const filterParams: any = {
      ...searchTextFilterValue,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };

    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    setSelectedPage(1);
    fetchRequestType(filterParams);
    setParams(filterParams);
  };

  const onPageClick = (e: any, value: number) => {
    e.preventDefault();
    setSelectedPage(value);
    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = value - 1;
    fetchRequestType(filterParams);
  };

  const onNextClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== totalPage) {
      setSelectedPage(selectedPage + 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
        ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage;
      fetchRequestType(filterParams);
    }
  };

  const onPreviousClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== 1) {
      setSelectedPage(selectedPage - 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
        ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage - 2;
      fetchRequestType(filterParams);
    }
  };

  useEffect(() => {
    let leftSide = selectedPage - 2;
    if (leftSide <= 0) leftSide = 1;
    let rightSide = selectedPage + 2;
    if (rightSide > totalPage) rightSide = totalPage;
    const pages: any = [];
    for (let number = leftSide; number <= rightSide; number++) {
      pages.push(number);
    }
    setPageItems(pages);
  }, [selectedPage, totalRequestCount]);

  const handleFormSubmit = (data: any) => {
    setSelectedPage(1);
    searchRef.current.clearSearch();
    // console.log('data in API', data);
    RequestTypeAPI.postFrontDeskRequestTypes(data)
      .then((res) => {
        if (res.data.errorCode === 0) {
          fetchRequestType(params);
          setSelectedPage(1);
          setShowModal(false);
          toast.success('Success');
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to create request type');
      });
  };

  const handleEditedFormSubmit = (data: any, id: string) => {
    setSelectedPage(1);
    searchRef.current.clearSearch();
    RequestTypeAPI.editFrontDeskRequestTypes(data, id)
      .then((res) => {
        console.log('data from edit rt api call', res.data);
        if (res.data.errorCode === 0) {
          fetchRequestType(params);
          setSelectedPage(1);
          setShowModal(false);
          toast.success('Success');
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to update trigger');
      });
  };

  const handleFetchById = (id: any) => {
    RequestTypeAPI.fetchSingleFrontDeskRequestTypes(id)
      .then((res) => {
        if (res.data.errorCode === 0) {
          console.log('editable res', res.data.data);
          setFormData(res.data.data);
          setRequestTypeId(id);
          setIsEditMode(true);
          setShowModal(true);
          setRequestType(res.data.data);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to fetch data');
      });
  };

  const handleDelete = (id: string) => {
    setSelectedPage(1);
    searchRef.current.clearSearch();
    // Delete - Automated Trigger
    RequestTypeAPI.deleteFrontDeskRequestTypes(id)
      .then((res) => {
        if (res.data.errorCode === 0) {
          fetchRequestType(params);
          setSelectedPage(1);
          setShowModal(false);
          toast.success('Successfully deleted');
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to delete trigger');
      });
  };

  useEffect(() => {
    console.log(' ', showModal);
    if (!showModal) {
      setRequestType({});
      setIsEditMode(false);
    }
  }, [showModal]);
  return (
    <div className="flex flex-col flex-auto p-11 pt-4 bg-background">
      <div className="flex flex-row items-center my-5">
        <h2 className="text-black text-2xl font-medium mr-8">
          {type === 'physician' ? 'Physician request types' : 'Front desk request types'}
        </h2>
        <Button
          type="primary"
          ownClasses=" px-4 mx-0.5 bg-white text-sm text-darkBlue rounded-full shadow-lg font-bold"
          onClick={() => setShowModal(true)}
        >
          <PlusSvg className="w-3.5 h-3.5 mr-1" />
          New
        </Button>
        {showModal && (
          <Modal showModal={showModal} setShowModal={setShowModal}>
            <div className="px-12 py-16 requestlisttb">
              <StandartHeader id={requestType._id} isEditMode={isEditMode} type={type} />
              <div className="relative  flex-auto">
                <Form
                  fetchRequestType={fetchRequestType}
                  setShowModal={setShowModal}
                  formData={requestType}
                  handleFormSubmit={handleFormSubmit}
                  handleEditedFormSubmit={handleEditedFormSubmit}
                  handleDelete={handleDelete}
                  isEditMode={isEditMode}
                ></Form>
              </div>
              <Footer />
            </div>
          </Modal>
        )}
      </div>
      <div className="flex flex-row flex-wrap gap-y-3 items-center justify-between my-5">
        <div className="flex flex-row flex-wrap items-center">
          <Button
            type={`${inlineFilterActive == 'All' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('All')}
          >
            <i className="w-2.5 h-2.5 mr-2 bg-white rounded-full" />
            All
          </Button>
          <Button
            type={`${inlineFilterActive == 'active' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('active')}
          >
            <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('active')} rounded-full`} />
            Active
          </Button>
          <Button
            type={`${inlineFilterActive == 'inactive' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('inactive')}
          >
            <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('inactive')} rounded-full`} />
            Inactive
          </Button>
        </div>
        <SearchForm
          filterSearchTextHandler={filterSearchTextHandler}
          type={type}
          sortField={sortKey}
          sortOrder={sortOrder}
          inlineFilterActive={inlineFilterActive}
          ref={searchRef}
        />
      </div>
      <Table
        headerItems={type === 'physician' ? headerItemsPhysicianRequestType : headerItemsFrontDeskRequestType}
        onSortClick={onSortClick}
      >
        {requestTypeList &&
          requestTypeList.map((eachRequest: any) => {
            return (
              <tr className="h-14 border-t border-lightGreyBackground" key={eachRequest._id}>
                {eachRequest.category && (
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p className="font-bold">{eachRequest.category}</p>
                  </td>
                )}

                {eachRequest.requestType && (
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p className="font-bold">{eachRequest.requestType}</p>
                  </td>
                )}

                {eachRequest.request && (
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p className="font-bold">{eachRequest.request}</p>
                  </td>
                )}
                <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                  <p>{eachRequest.routeTo}</p>
                </td>
                {eachRequest.triggers && (
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    {eachRequest?.triggers?.length > 0 ? (
                      <p className="text-left">
                        {eachRequest?.triggers?.map((eachTrigger: any) => eachTrigger)?.join(', ')}
                      </p>
                    ) : (
                      <p className="text-left">-</p>
                    )}
                  </td>
                )}
                {eachRequest.triggerId && (
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p className="font-bold">{eachRequest.triggerId[0]}</p>
                  </td>
                )}
                <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                  <p>{eachRequest._id}</p>
                </td>
                <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                  <p className="text-midBlue capitalize">{eachRequest.status}</p>
                </td>
                <td className="px-6 pb-4 pt-5 text-center">
                  <button
                    onClick={() => {
                      // setRequestType(eachRequest);
                      handleFetchById(eachRequest?._id);
                      setIsEditMode(true);
                      setShowModal(true);
                    }}
                  >
                    <EditSvg className="w-4" />
                  </button>
                </td>
              </tr>
            );
          })}
      </Table>
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <a
            href="#"
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </a>
          <a
            href="#"
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </a>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            {totalRequestCount > 0 ? (
              <p className="text-sm text-gray-700">
                Showing{' '}
                <span className="font-medium">{selectedPage === 1 ? selectedPage : (selectedPage - 1) * 10 + 1}</span>{' '}
                to{' '}
                <span className="font-medium">
                  {selectedPage * 10 > totalRequestCount ? totalRequestCount : selectedPage * 10}
                </span>{' '}
                of <span className="font-medium">{totalRequestCount}</span> request types
              </p>
            ) : (
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{totalRequestCount ? totalRequestCount : 0}</span> request types
              </p>
            )}
          </div>
          <div>
            {totalRequestCount ? (
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <a
                  onClick={(e) => onPreviousClick(e)}
                  href="#"
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </a>
                {pageItems.map((item: any) => (
                  <a
                    href="#"
                    key={item}
                    aria-current="page"
                    onClick={(e) => onPageClick(e, item)}
                    className={`z-10 ${
                      selectedPage === item ? 'bg-green text-black' : 'border-gray-300 text-gray-500 bg-white'
                    }  relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                  >
                    {item}
                  </a>
                ))}
                <a
                  href="#"
                  onClick={(e) => onNextClick(e)}
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </a>
              </nav>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardTable;
