import TaskApi from 'api/TaskAPI';
import axios from 'axios';
import QueueList from 'components/QueueList';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { toast } from 'react-toastify';
import socket from 'socket/socket';
import { setTaskLists, setTotalTask, setUrgentTaskCount } from '../../redux/tasks/actions';

const Task: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const tasksList = useSelector((state: RootStateOrAny) => state.tasks.taskList);
  const [source, setSource] = useState<any>();
  const fetchTasksList = async (value?: any) => {
    setLoading(true);

    if (typeof source != typeof undefined) {
      source.cancel('Operation cancelled due to new request.');
    }
    const cancelToken = axios.CancelToken.source();
    setSource(cancelToken);

    try {
      await TaskApi.fetchTasks(value, cancelToken.token)
        .then((res) => {
          if (res.data.errorCode === 0) {
            dispatch(setTaskLists(res.data.data));
            dispatch(setTotalTask(res.data.info.totalCount));
            setLoading(false);
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Request cancelled!');
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchTasksList({ pageNo: 0, limit: 50 });
    fetchUrgentCount();
  }, [dispatch]);

  useEffect(() => {
    try {
      // socket.off('taskDetailUpdate');
      socket.on('taskDetailUpdate', (data) => {
        if (data) {
          tasksList &&
            tasksList.map((eachTask: any) => {
              if (eachTask._id == data.tasksIdList && data.details.note.length > 0) {
                eachTask.details.note = data.details.note;
              }
            });
          dispatch(setTaskLists(tasksList));
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, [tasksList]);

  const fetchUrgentCount = () => {
    TaskApi.fetchUrgentTaskCount()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          dispatch(setUrgentTaskCount(res.data.data.urgentTasksCount));
        }
      })
      .catch((err) => {
        if (err?.data?.message) {
          toast.error(err.data.message);
        } else {
          toast.error('Failed to refresh Urgent Task Count');
        }
      });
  };

  return <QueueList fetchTasksList={fetchTasksList} loading={loading} fetchUrgentCount={fetchUrgentCount} />;
};

export default Task;
