import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

const appointmentAppendPath = 'appointments';

export default class AppointmentAPI {
  static fetchAppointments(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${appointmentAppendPath}/calendar`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchGlobalAppointments(data: any) {
    const { startDate, endDate, timezone, doctorIDs } = data;
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${appointmentAppendPath}/global-calendar`,
      params: { startDate, endDate, timezone },
      method: 'POST',
      data: { physicianIds: doctorIDs },
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  static fetchGlobalAvailabilityCombined(data: any) {
    const { startDate, endDate, localTz } = data;
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/global-availability/combined?startDate=${startDate}&endDate=${endDate}`,
      method: 'GET',
      params: { localTz },
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  static fetchGlobalAvailability(data: any) {
    const { date, localTz } = data;
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/global-availability?date=${date}`,
      method: 'GET',
      params: { localTz },
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchSingleAppointment(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${appointmentAppendPath}/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchCallToken(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${appointmentAppendPath}/${id}/call-token`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static completeAppointment(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${appointmentAppendPath}/${id}/mark-complete`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  static cancelAppointment(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${appointmentAppendPath}/${id}/cancel`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static visitTypeAppointment(id: string, params: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${appointmentAppendPath}/${id}/visit-type`,
      method: 'PATCH',
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
