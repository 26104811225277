import Button from 'components/common/Button';
import DateTimePicker from 'components/common/DateTimePicker';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';

export interface FormProps {
  formHandler: (value?: any) => void;
  filterFormValues?: any;
}

const Form: React.FC<FormProps> = ({ formHandler, filterFormValues }) => {
  const [patient, setPatient] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [physician, setPhysician] = useState('');
  const [email, setEmail] = useState('');
  const [membership, setMembership] = useState('');
  const [creationDate, setCreationDate] = useState('');
  const [status, setStatus] = useState<{ label: string; value: string } | null>();
  const [filterCount, setFilterCount] = useState(0);

  const membershipOptions = [
    { value: 'permium', label: 'Premium' },
    { value: 'elite', label: 'Elite' },
  ];

  const statuses = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  useEffect(() => {
    setPatient(filterFormValues.patient);
    setPhysician(filterFormValues.doctor);
    setStatus(statuses.find((item) => item.value === filterFormValues.status));
    setUniqueId(filterFormValues.uniqueId);
    setEmail(filterFormValues.email);
    setMembership(filterFormValues.membership);
    setCreationDate(filterFormValues.createdAt);
  }, [filterFormValues]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const filterData = {
      ...(patient && { patient }),
      ...(physician && { doctor: physician }),
      ...(status && { status: status.value }),
      ...(uniqueId && { uniqueId }),
      ...(email && { email }),
      ...(membership && { membership }),
      ...(creationDate && { createdAt: moment(creationDate).format('YYYY-MM-DD') }),
    };
    formHandler(filterData);
  };

  const handleClear = (e: any) => {
    e.preventDefault();
    formHandler({});
  };

  const checkFilterApplied = () => {
    let count = 0;
    if (patient) {
      count += 1;
    }
    if (physician) {
      count += 1;
    }
    if (uniqueId) {
      count += 1;
    }
    if (email) {
      count += 1;
    }
    if (creationDate) {
      count += 1;
    }
    if (status) {
      count += 1;
    }
    if (membership) {
      count += 1;
    }
    setFilterCount(count);
  };

  useEffect(() => {
    checkFilterApplied();
  }, [patient, physician, membership, status, creationDate, uniqueId, email]);
  return (
    <form>
      <div className="flex flex-col lg:flex-row justify-center items-center">
        <div className="mr-20 mb-4">
          <Input
            labelText="Patient"
            name="patient"
            placeholder="Enter patient"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-xl  mb-4"
            value={patient}
            onChange={(e) => setPatient(e.target.value)}
            onClickIconClear={() => setPatient('')}
            closeIcon={true}
          />
          <Input
            labelText="Doctor"
            name="physician"
            placeholder="Enter physician"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-xl  mb-4"
            value={physician}
            onChange={(e) => setPhysician(e.target.value)}
            onClickIconClear={() => setPhysician('')}
            closeIcon={true}
          />
          <Input
            labelText="Email"
            name="email"
            placeholder="Enter Email"
            inputClasses="w-52  mb-4 text-clip"
            labelClasses="mr-10 text-xl  mb-4"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onClickIconClear={() => setEmail('')}
            closeIcon={true}
          />
          <div className="mb-4">
            <Input
              labelText="Membership"
              placeholder="Membership"
              labelClasses="mr-10 text-xl"
              inputClasses="w-52 mb-4 pr-8"
              value={membership}
              onChange={(e: any) => setMembership(e.target.value)}
              onClickIconClear={() => setMembership('')}
              closeIcon={true}
            />
          </div>
        </div>
        <div>
          <Input
            labelText="Unique Id"
            name="created"
            placeholder="Enter Id"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-xl  mb-4"
            value={uniqueId}
            onChange={(e) => setUniqueId(e.target.value)}
            onClickIconClear={() => setUniqueId('')}
            closeIcon={true}
          />
          <DateTimePicker
            placeholder="Select date"
            name="created"
            labelText="Creation Date"
            inputClasses="w-52  mb-4"
            labelClasses="mr-10 text-xl  mb-4"
            format="YYYY-MM-DD"
            value={creationDate}
            onChange={(e) => setCreationDate(e)}
          />
          <div className="mb-4">
            <Dropdown
              labelText="Status"
              placeholder="Status"
              labelClasses="mr-10 text-xl  mb-4"
              width="52"
              value={status}
              options={statuses}
              onClickIconClear={() => setStatus(null)}
              closeIcon={true}
              onChange={(e: any) => setStatus(e)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <p>{filterCount} filters applied</p>
        <Button
          ownClasses="px-7 mx-2.5 font-semibold text-dashboardPrimaryGrey"
          type="depressed"
          onClick={(e) => handleClear(e)}
        >
          Clear all
        </Button>
        <Button ownClasses="px-7 font-semibold rounded-full" type="success" onClick={(e) => handleSubmit(e)}>
          Update
        </Button>
      </div>
    </form>
  );
};

export default Form;
