import React, { useEffect, useRef, useState } from 'react';
import { RemoteVideoTrack, LocalVideoTrack, LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import classNames from 'classnames';
import useVideoCall from '../../hooks/useVideoCall';
import Avatar from '../CommonAvatar';
import { ParticipantProps } from './participant.types';
import { pollAudioLevel } from 'utils/pollAudioLevel';

const Participant: React.FC<ParticipantProps> = ({ profileImage, videoTrack, audioTrack, isLocal }) => {
  const [volume, setVolume] = useState(0);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [videoEnabled, setVideoEnabled] = useState<boolean>(true);
  const { speakerId } = useVideoCall();

  const toggleVideo = (track: RemoteVideoTrack | LocalVideoTrack) => {
    setVideoEnabled(track.isEnabled);
  };

  const onAudioTrackStarted = (track: LocalAudioTrack | RemoteAudioTrack) => {
    if (track instanceof LocalAudioTrack) {
      pollAudioLevel(track, setVolume);
    }
  };

  /**
   * Subscribe on videoTrack and attach to video tag
   * Disable or enable video track when patient changed video deviceId and before that disabled or enabled video device
   */
  useEffect(() => {
    if (videoTrack && videoRef.current) {
      videoTrack.attach(videoRef.current);
      toggleVideo(videoTrack);
      videoTrack.on('disabled', toggleVideo);
      videoTrack.on('enabled', toggleVideo);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTrack]);

  /**
   * Attach audioTrack to audio tag
   */
  useEffect(() => {
    if (audioTrack && audioRef.current) {
      pollAudioLevel(audioTrack, setVolume);
      audioTrack.attach(audioRef.current);
      audioTrack.on('started', onAudioTrackStarted);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTrack]);

  /**
   * Change speaker device when patient changes speakerId
   */
  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    audioRef.current?.setSinkId && audioRef.current.setSinkId(speakerId);
  }, [speakerId]);

  return (
    <>
      <video ref={videoRef} autoPlay controls={false} className="w-full h-full object-cover" />
      <audio ref={audioRef} autoPlay />
      <div
        className={classNames('w-full h-full absolute inset-0 bg-white flex items-center justify-center', {
          hidden: videoEnabled,
        })}
      >
        <div className="bg-darkBlue rounded-full transition-all" style={{ padding: `${volume * 1.5}px` }}>
          <Avatar
            src={profileImage}
            ownClasses={classNames(
              'pointer-events-none',
              isLocal ? 'w-9 h-9 sm:w-11 sm:h-11 md:w-14 md:h-14 lg:w-20 lg:h-20' : 'w-40 h-40',
            )}
          />
        </div>
      </div>
    </>
  );
};

export default Participant;
