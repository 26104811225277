import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import ChatInput from './ChatInput';
import ChatVideo from '../ChatVideo';
import ChatHeader from './ChatHeader';
import ChatWindow from './ChatWindow';
import { ReactComponent as RightSvg } from '../../assets/icons/chat/right.svg';
import TaskApi from 'api/TaskAPI';
import PatientAPI from 'api/PatientAPI';
import socket from 'socket/socket';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useSelector, RootStateOrAny } from 'react-redux';
import * as Sentry from '@sentry/react';

const ChatLayout = () => {
  const [tab, setTab] = useState<'messages' | 'chat'>('messages');
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const [isOpenChat, setIsOpenChat] = useState(true);
  const [taskDetails, setTaskDetails] = useState({} as any);
  const [patientInformation, setPatientInformation] = useState({} as any);
  const [patientChat, setPatientChat] = useState([] as any);
  const [messageSent, setMessageSend] = useState(false);
  const [callDetails, setCallDetails] = useState({} as any);
  const [messageRecvied, setMessageRecived] = useState(false);
  const [messagesRecivedList, setMessagesRecivedList] = useState<any>();
  const [enterRoom, setEnterRoom] = useState(false);
  const [newMessageNotification, setNewMessageNotification] = useState(false);

  const [newMessageSend, setNewMessageSend] = useState(false);

  const history = useHistory();
  const clearCallDetails = () => {
    setCallDetails({});
    Cookies.remove('appointmentId');
    Cookies.remove('doctorId');
    Cookies.remove('patientId');
    Cookies.remove('taskId');
    Cookies.remove('channelGeneral');
    socket.off('joinedWaitingRoom');
    socket.off('patientTriggeredCall');
  };

  const fetchPatientDetails = () => {
    const id: any = Cookies.get('patientId');
    PatientAPI.fetchSinglePatient(id)
      .then((res) => {
        if (res.data) {
          const { data } = res.data;
          setPatientInformation(data);
        }
      })
      .finally(() => {
        console.log('loaded...');
      });
  };

  const fetchTaskDetails = () => {
    const id: any = Cookies.get('taskId');
    TaskApi.fetchSingleTask(id)
      .then((res) => {
        if (res.data) {
          const { data } = res.data;
          setTaskDetails(data);
          const callInfo = {
            taskId: data._id,
            doctorId: data.appointmentInfo.doctorId,
            patientId: data.patientId,
            appointmentId: data.appointmentInfo._id,
            appointmentMethod: data.appointmentInfo.appointmentMethod,
            staffName: data.appointmentInfo.staffName,
            patientName: data.personalInfo.firstName + ' ' + data.personalInfo.lastName,
            patientImage: data.personalInfo?.profileImage,
            doctorImage: userInfo?.profileImage,
            doctorName: userInfo?.firstName,
          };
          setCallDetails(callInfo);
        } else {
          history.push('/dashboard/tasks');
          toast.error('Unable to intiate appointment, Please try again!');
          clearCallDetails();
        }
      })
      .catch((e) => {
        history.push('/dashboard/tasks');
        toast.error('Unable to intiate appointment, Please try again!');
        clearCallDetails();
      })
      .finally(() => {
        console.log('loaded...');
      });
  };

  const chatSocketInit = () => {
    socket.emit(
      'getNextMessage',
      {
        limit: 20,
        skip: 0,
        channelId: Cookies.get('channelGeneral'),
      },
      (data: any) => {
        console.log(data, 'soketemmit');
      },
    );
  };

  const chatSendMessages = (message: string, imageInput: any) => {
    const id: any = Cookies.get('patientId');
    const doctorId: any = Cookies.get('doctorId');
    try {
      console.log(message);
      socket.emit(
        'newMessageToServer',
        {
          message: message,
          fileName: imageInput ? (imageInput.fileName ? imageInput.fileName : null) : null,
          filePath: imageInput ? (imageInput.filePath ? imageInput.filePath : null) : null,
          receiverDetails: [{ id: id }],
          senderDetails: { id: doctorId, userType: userInfo.userType },
          channelId: Cookies.get('channelGeneral'),
        },
        true,
        function (data: any) {
          if (data.status) {
            setMessageSend(true);
          } else {
            setMessageSend(false);
          }
        },
      );
    } catch (e) {
      Sentry.captureException(e);
      setMessageSend(false);
    }
  };

  useEffect(() => {
    if (messageRecvied) {
      chatSocketInit();
      setMessageRecived(false);
    }
  }, [messageRecvied]);

  useEffect(() => {
    fetchTaskDetails();
    fetchPatientDetails();
    chatSocketInit();
    socket.emit('joinRoom', Cookies.get('channelGeneral'), (data: any) => {
      console.log('=======joined channel==========');
    });
    socket.on('oldMessages', (data) => {
      if (data[0]) {
        const listData = data.slice(0).reverse();
        setPatientChat(listData);
      }
    });

    socket.on('messageToClients', (data) => {
      setMessageRecived(true);
      setMessagesRecivedList(data);
    });

    socket.on('unreadMessageUpdated', (data) => {
      if (data.channelId === Cookies.get('channelGeneral')) {
        if (data.unreadMessageCount > 0) {
          setNewMessageNotification(true);
        } else {
          setNewMessageNotification(false);
        }
      }
    });
  }, []);

  const clearNotification = () => {
    const doctorId: any = Cookies.get('doctorId');
    if (messagesRecivedList) {
      socket.emit(
        'markSeen',
        {
          messageId: messagesRecivedList._id,
          channelId: messagesRecivedList.channelId,
          userId: doctorId,
        },
        (data: any) => {
          setNewMessageNotification(false);
        },
      );
    }
  };

  useEffect(() => {
    if (callDetails.doctorId) {
      setEnterRoom(true);
    }
  }, [callDetails]);

  const onNewMessageSend = () => {
    setNewMessageSend(false);
  };

  return enterRoom ? (
    <div className="flex w-full relative">
      <div className={classNames('w-full', isOpenChat && 'w-1/2 md:w-1/2 lg:w-full')}>
        <ChatVideo callDetails={callDetails} clearCallDetails={clearCallDetails} />
      </div>

      <div className={classNames(`relative`, isOpenChat ? 'w-full md:w-1/2 lg:w-1/4' : 'w-0')}>
        {isOpenChat && (
          <div
            className={classNames(
              'overflow-y-auto flex flex-col max-h-screen min-h-screen',
              tab === 'messages' && 'justify-between',
              tab === 'chat' && 'justify-start',
            )}
          >
            <ChatHeader tab={tab} setTab={setTab} patientInformation={patientInformation} />
            {tab === 'messages' && (
              <ChatWindow
                patientChat={patientChat && patientChat}
                newMessageSend={newMessageSend}
                onNewMessageSend={onNewMessageSend}
              />
            )}
            {tab === 'messages' && (
              <ChatInput chatSendMessage={chatSendMessages} clearNotification={clearNotification} />
            )}
          </div>
        )}
        <button
          onClick={() => setIsOpenChat(!isOpenChat)}
          className={classNames(
            'focus:outline-none',
            'bg-gray-100 w-8 p-2 h-32 flex justify-center items-center absolute top-0 bottom-0 m-auto z-10',
            isOpenChat ? 'left-0 md:-left-4' : '-left-8 md:-left-8',
          )}
        >
          <div className="relative">
            <div
              className={`${newMessageNotification ? 'bg-red' : 'bg-gray-100'} rounded-full h-5 w-5 absolute bottom-10`}
            />
            <RightSvg className={classNames('fill-current text-gray-600', !isOpenChat && 'transform rotate-180')} />
          </div>
        </button>
      </div>
      {/* </div> */}
    </div>
  ) : (
    <>
      <LoadingOverlay active={!enterRoom}>
        <div className="h-screen"></div>
      </LoadingOverlay>
    </>
  );
};

export default ChatLayout;
