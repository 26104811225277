import Label from '../Label';

interface ITextarea {
  labelText?: string;
  labelClasses?: string;
  placeholder?: string;
  required?: boolean;
  hintElement?: string | JSX.Element;
  name?: string;
  rows?: number;
  value?: string;
  handleChange?: (value: any) => any;
}
const TextArea = ({
  labelText,
  labelClasses,
  placeholder,
  required = true,
  hintElement,
  name,
  value,
  handleChange,
  rows = 3,
}: ITextarea) => {
  return (
    <div className="mb-4 flex items-center">
      <Label labelText={labelText} labelClasses={labelClasses} />
      <div className="w-full">
        <textarea
          rows={rows}
          name={name}
          className="w-full bg-background border border-lightBorder rounded-lg pl-2 pt-2 text-medium text-light"
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          required={required}
        ></textarea>

        {hintElement && <p className="text-gray70 text-xs">{hintElement}</p>}
      </div>
    </div>
  );
};

export default TextArea;
