import Table from '../common/Table';
import SearchForm from './SearchForm';
import { ReactComponent as EyeSvg } from '../../assets/icons/eye.svg';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import ArticleView from './ArticleView';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import Articles from 'pages/Admin/Articles';
import ArticleAPI from 'api/ArticleAPI';
import { toast } from 'react-toastify';
import { ReactComponent as PlusSvg } from '../../assets/icons/lifeMD-plus.svg';
import Button from '../common/Button';
import classNames from 'classnames';
import { CheckCategoryColor } from 'utils/CheckCategoryColor';
import { dateTimeFormatter } from 'utils/dateTimeFormatter';
import LookUpAPI from 'api/LookUpsAPI';
export interface ArticleProps {
  fetchArticlesList: (value?: any) => void;
  loading: boolean;
}

const ArticleTable: React.FC<ArticleProps> = ({ fetchArticlesList, loading }) => {
  const userRole = useSelector((state: RootStateOrAny) => state.userProfile.userDetails.userType.name);
  const articleList = useSelector((state: RootStateOrAny) => state.articles.articleList);
  const [dataList, setDataList] = useState([]);
  const [inlineFilterActive, setInlineFilterActive] = useState('All');
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchFilterParams, setSearchFilterParams] = useState({});
  const [pageItems, setPageItems] = useState([]);
  const totalArticleCount = useSelector((state: RootStateOrAny) => state.articles.totalCount);
  const totalPage =
    totalArticleCount % 10 === 0 ? Math.floor(totalArticleCount / 10) : Math.floor(totalArticleCount / 10) + 1;
  const [articleId, setArticleId] = useState('');
  const [articleStatus, setArticleStatus] = useState('list');
  const [authorOptions, setAuthorOptions] = useState([] as any);
  const [audienceOptions, setAudienceOptions] = useState([] as any);
  const [filterAudienceOptions, setFilterAudienceOptions] = useState([] as any);
  const [articleData, setArticleData] = useState<any>();
  const [sortKey, setSortKey] = useState<string | undefined>('');
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [params, setParams] = useState<any>();

  const adminHeaderItems = [
    {
      title: 'Title',
      sortKey: 'title',
    },
    {
      title: 'Audience',
      sortKey: 'audience.shortCode',
    },
    {
      title: 'Category',
      sortKey: 'category',
    },
    {
      title: 'Author',
      sortKey: 'author.name',
    },
    {
      title: 'Date created',
      sortKey: 'createdAt',
    },
    {
      title: 'Unique id',
      sortKey: 'uniqueId',
    },
    {
      title: 'Status',
      sortKey: 'status',
    },
    {
      title: 'Actions',
      align: 'center',
    },
  ];
  const headerItems = [
    {
      title: 'Title',
      sortKey: 'title',
    },
    {
      title: 'Category',
      sortKey: 'category',
    },
    {
      title: 'Author',
      sortKey: 'author.name',
    },
    {
      title: 'Date created',
      sortKey: 'createdAt',
    },
    {
      title: 'Actions',
      align: 'center',
    },
  ];

  const onSortClick = (title?: string, key?: string, sortOrder?: string) => {
    setSelectedPage(1);
    setSortKey(key);
    setSortOrder(sortOrder);
    const filterParams: any = {
      ...searchFilterParams,
      ...(key && { sortField: key }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    fetchArticlesList(filterParams);
    setParams(filterParams);
  };

  const inlineStatusHandler = (inlineCategoryValue: string) => {
    setInlineFilterActive(inlineCategoryValue);
    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = 0;
    filterParams['status'] = inlineCategoryValue !== 'All' ? inlineCategoryValue : null;
    setSelectedPage(1);
    fetchArticlesList(filterParams);
    setParams(filterParams);
  };

  const filterSearchTextHandler = (searchTextFilterValue?: any) => {
    setSearchFilterParams(searchTextFilterValue);
    const filterParams: any = {
      ...searchTextFilterValue,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    setSelectedPage(1);
    fetchArticlesList(filterParams);
    setParams(filterParams);
  };

  const onPageClick = (e: any, value: number) => {
    e.preventDefault();
    setSelectedPage(value);
    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = value - 1;
    fetchArticlesList(filterParams);
  };

  const onNextClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== totalPage) {
      setSelectedPage(selectedPage + 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
        ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage;
      fetchArticlesList(filterParams);
    }
  };

  const onPreviousClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== 1) {
      setSelectedPage(selectedPage - 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
        ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage - 2;
      fetchArticlesList(filterParams);
    }
  };

  useEffect(() => {
    let leftSide = selectedPage - 2;
    if (leftSide <= 0) leftSide = 1;
    let rightSide = selectedPage + 2;
    if (rightSide > totalPage) rightSide = totalPage;
    const pages: any = [];
    for (let number = leftSide; number <= rightSide; number++) {
      pages.push(number);
    }
    setPageItems(pages);
  }, [selectedPage, totalArticleCount]);

  const onActionClickHandler = (action: string, id: string) => {
    setEditMode(true);
    setArticleId(id);
    setArticleStatus(action);
  };

  const backToListHandler = () => {
    setArticleId('');
    setArticleStatus('list');
    setInlineFilterActive('All');
    setArticleData('');
    setSelectedPage(1);
    fetchArticlesList(params);
  };

  const fetchStaffOptions = () => {
    ArticleAPI.fetchStaffs().then((res) => {
      const staffData: any = [];
      const { data } = res.data;
      if (data) {
        data?.map((eachItem: any) => {
          staffData.push({ label: eachItem?.name, value: eachItem?._id });
        });
        setAuthorOptions(staffData);
      }
    });
  };

  const fetchUserRoles = () => {
    LookUpAPI.fetchUserTypes().then((res) => {
      if (res.data.errorCode === 0) {
        console.log('Lookup User Type', res.data);
        const { data } = res?.data;
        const rolesData: any = [];
        const userTypes: any = [];
        if (data) {
          data?.map((eachItem: any) => {
            rolesData.push({ label: eachItem?.name, value: eachItem?.name });
            userTypes.push({ label: eachItem?.shortCode, value: eachItem?.shortCode });
          });
          rolesData.unshift({ label: 'Admin', value: 'Admin' });
          userTypes.unshift({ label: 'AD', value: 'AD' });
          setAudienceOptions(rolesData);
          console.log('rolesData', rolesData, 'userTypes', userTypes);
          setFilterAudienceOptions(userTypes);
        }
      }
    });
  };

  useEffect(() => {
    if (userRole === 'Admin') {
      fetchStaffOptions();
      fetchUserRoles();
    }
  }, []);

  const createArticle = (data: any) => {
    setSelectedPage(1);
    ArticleAPI.createArticle(data)
      .then((res) => {
        toast.success(res?.data?.message);
        setArticleStatus('list');
        fetchArticlesList(params);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      });
  };

  const fetchArticleById = () => {
    ArticleAPI.fetchSingleArticle(articleId).then((res) => {
      if (res.data.data) {
        setArticleData(res?.data?.data);
      }
    });
  };

  const editArticleData = (data: any) => {
    setSelectedPage(1);
    ArticleAPI.editArticle(data, articleId)
      .then((res) => {
        toast.success(res?.data?.message);
        setArticleStatus('list');
        setArticleId('');
        fetchArticlesList(params);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      });
  };

  const deleteArticle = (articleId: string) => {
    setSelectedPage(1);
    ArticleAPI.deleteArticleById(articleId)
      .then((res) => {
        if (res.data.errorCode === 0) {
          toast.success(res?.data?.message);
          setArticleStatus('list');
          setSelectedPage(1);
          setArticleId('');
          fetchArticlesList(params);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (articleId) {
      fetchArticleById();
    }
  }, [articleId]);

  useEffect(() => {
    if (articleList) {
      if (userRole === 'Admin') {
        setDataList(articleList);
      } else {
        setDataList(articleList?.filter((article: any) => article?.status === 'active'));
      }
    }
  }, [articleList]);

  return (
    <div className="flex flex-col flex-auto px-11 pt-4 bg-background">
      <div className="flex flex-row items-center my-5">
        <h2 className="text-black text-2xl font-semibold mr-3">Articles</h2>
        {articleStatus === 'list' && userRole === 'Admin' && (
          <Button
            type="primary"
            ownClasses=" px-4 mx-0.5 bg-white text-sm text-darkBlue rounded-full shadow-lg font-bold"
            onClick={() => setArticleStatus('add')}
          >
            <PlusSvg className="w-3.5 h-3.5 mr-1" />
            New
          </Button>
        )}
        {articleStatus !== 'list' && (
          <button className="ml-1 text-xl text-gray-400 underline" onClick={() => backToListHandler()}>
            {' '}
            &lt; Back to list
          </button>
        )}
      </div>
      {articleStatus === 'list' && (
        <>
          <div
            className={classNames(
              'flex flex-row flex-wrap items-center my-5',
              { 'justify-between': userRole === 'Admin' },
              { 'justify-end': userRole !== 'Admin' },
            )}
          >
            {userRole === 'Admin' && (
              <div className="flex flex-row flex-wrap items-center">
                <Button
                  type={`${inlineFilterActive == 'All' ? 'success' : 'secondary'}`}
                  ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
                  onClick={() => inlineStatusHandler('All')}
                >
                  <i className="w-2.5 h-2.5 mr-2 bg-white rounded-full" />
                  All
                </Button>
                <Button
                  type={`${inlineFilterActive == 'active' ? 'success' : 'secondary'}`}
                  ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
                  onClick={() => inlineStatusHandler('active')}
                >
                  <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('active')} rounded-full`} />
                  Active
                </Button>
                <Button
                  type={`${inlineFilterActive == 'inactive' ? 'success' : 'secondary'}`}
                  ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
                  onClick={() => inlineStatusHandler('inactive')}
                >
                  <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('inactive')} rounded-full`} />
                  Inactive
                </Button>
              </div>
            )}
            <SearchForm
              filterSearchTextHandler={filterSearchTextHandler}
              searchFilterParams={searchFilterParams}
              audienceOptions={filterAudienceOptions}
              sortField={sortKey}
              sortOrder={sortOrder}
              inlineFilterActive={inlineFilterActive}
            />
          </div>
          <LoadingOverlay active={loading} spinner>
            <Table headerItems={userRole === 'Admin' ? adminHeaderItems : headerItems} onSortClick={onSortClick}>
              {dataList &&
                dataList?.map((eachArticle: any) => {
                  return (
                    <tr className="h-14 border-t border-lightGreyBackground" key={eachArticle?._id}>
                      <td className="truncate w-44 px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                        <p>{eachArticle?.title}</p>
                      </td>
                      {userRole === 'Admin' && (
                        <td className="truncate w-44 px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                          {eachArticle?.audience?.length > 0 ? (
                            <p className="truncate w-36">
                              {eachArticle?.audience?.map((eachAudience: any) => eachAudience?.shortCode)?.join(', ')}
                            </p>
                          ) : (
                            <p>-</p>
                          )}
                        </td>
                      )}{' '}
                      <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                        <p>{eachArticle?.category}</p>
                      </td>
                      <td className="w-44 px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                        <p>{eachArticle?.author?.name}</p>
                      </td>
                      <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                        <p>{dateTimeFormatter(eachArticle.createdAt)}</p>
                      </td>
                      {userRole === 'Admin' && (
                        <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                          <p>{eachArticle?._id}</p>
                        </td>
                      )}
                      {userRole === 'Admin' && (
                        <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                          <p className="capitalize">{eachArticle?.status}</p>
                        </td>
                      )}
                      <td className="px-6 pb-4 pt-5 text-center cursor-pointer">
                        <p className="flex justify-evenly">
                          {userRole === 'Admin' && (
                            <button onClick={() => onActionClickHandler('edit', eachArticle?._id)}>
                              <EditSvg className="w-5" />
                            </button>
                          )}
                          <button onClick={() => onActionClickHandler('view', eachArticle?._id)}>
                            <EyeSvg className="w-6" />
                          </button>
                        </p>
                      </td>
                    </tr>
                  );
                })}
            </Table>
          </LoadingOverlay>
          <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <a
                href="#"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Previous
              </a>
              <a
                href="#"
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Next
              </a>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                {totalArticleCount > 0 ? (
                  <p className="text-sm text-gray-700">
                    Showing{' '}
                    <span className="font-medium">
                      {selectedPage === 1 ? selectedPage : (selectedPage - 1) * 10 + 1}
                    </span>{' '}
                    to{' '}
                    <span className="font-medium">
                      {selectedPage * 10 > totalArticleCount ? totalArticleCount : selectedPage * 10}
                    </span>{' '}
                    of <span className="font-medium">{totalArticleCount}</span> Articles
                  </p>
                ) : (
                  <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{totalArticleCount}</span> Articles
                  </p>
                )}
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  <a
                    onClick={(e) => onPreviousClick(e)}
                    href="#"
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </a>
                  {pageItems?.map((item: any) => (
                    <a
                      href="#"
                      key={item}
                      aria-current="page"
                      onClick={(e) => onPageClick(e, item)}
                      className={`z-10 ${
                        selectedPage === item ? 'bg-green text-black' : 'border-gray-300 text-gray-500 bg-white'
                      }  relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                    >
                      {item}
                    </a>
                  ))}
                  <a
                    href="#"
                    onClick={(e) => onNextClick(e)}
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </>
      )}
      {articleStatus === 'view' && articleId && (
        <div>
          <ArticleView articleId={articleId} articleData={articleData} />
        </div>
      )}
      {(articleStatus === 'edit' || articleStatus === 'add') && (
        <div>
          <Articles
            audienceOptions={audienceOptions}
            authorOptions={authorOptions}
            addArticle={createArticle}
            editArticle={editArticleData}
            deleteArticle={deleteArticle}
            articleData={articleData}
            type={articleStatus}
          />
        </div>
      )}
    </div>
  );
};

export default ArticleTable;
