import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

const taskAppendPath = 'tasks';

export default class TaskApi {
  static fetchTasks(data?: any, cancel?: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${taskAppendPath}`,
      method: 'GET',
      params: {
        isMyPatients: false,
        ...data,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: cancel,
    });
  }

  static fetchSingleTask(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${taskAppendPath}/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static setTaskComplete(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${taskAppendPath}/${id}/mark-complete`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static setTaskAssign(id: string, assignTo: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${taskAppendPath}/${id}/assign`,
      method: 'PATCH',
      data: assignTo,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static setTaskHandOver(id: string, params: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${taskAppendPath}/${id}/handover`,
      method: 'PATCH',
      params: params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static setTaskRequestTakeOver(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${taskAppendPath}/${id}/takeover`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static setTaskReviewTakeOver(id: string, params: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${taskAppendPath}/${id}/review-takeover`,
      method: 'PATCH',
      params: params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static sendTaskStaffNotes(id: string, data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${taskAppendPath}/${id}/staff-notes`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static updateTaskIntakeItems({ id }: { id: string }, data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${taskAppendPath}/${id}/update-intake-items`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static sendTaskToPhysician(id: string, data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${taskAppendPath}/${id}/send-to-physician`,
      method: 'PATCH',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static toogleStatus(id: string, data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${taskAppendPath}/${id}/toogle-status`,
      method: 'PATCH',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static changeTaskChannel(id?: string, data?: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${taskAppendPath}/${id}/channel`,
      method: 'PATCH',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchUrgentTaskCount() {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${taskAppendPath}/urgent-tasks-count`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
