import AuthAPI from 'api/AuthAPI';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoggedIn } from '../redux/authentication/actions';
import Header from './Header/Header';
import { Redirect, useHistory } from 'react-router';
import Cookies from 'js-cookie';
import LoadingOverlay from 'react-loading-overlay-ts';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import LogRocket from 'logrocket';

const Login: React.FC = () => {
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const loginHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = {
      email: userEmail,
      password: userPassword,
    };
    setLoading(true);
    AuthAPI.login(data)
      .then((res) => {
        if (res.data.errorCode === 0) {
          LogRocket.identify(userEmail);
          window.localStorage.setItem('accessToken', res.data.data.accessToken);
          window.localStorage.setItem('refreshToken', res.data.data.refreshToken);
          dispatch(setLoggedIn(res.data.data._id));
          history.push('/dashboard/tasks');
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        const { response } = error;
        if (response?.data?.message) {
          toast.error(response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return window.localStorage.getItem('accessToken') && window.localStorage.getItem('refreshToken') ? (
    <Redirect to="/dashboard/tasks" />
  ) : (
    <LoadingOverlay active={loading} spinner>
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="max-w-xl mx-auto">
          <div className="text-center">
            <p className="text-6xl text-darkBlue mb-3">Welcome back!</p>
            <p className="text-3xl mb-3">Sign in to your LifeMD account.</p>
          </div>
          <form className="mt-8 space-y-6" onSubmit={loginHandler}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="rounded relative  block w-full px-3 py-3 mb-6 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </div>
              <div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="placeholder-black-500  rounded relative block w-full px-3 py-3 mb-6 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  onChange={(e) => setUserPassword(e.target.value)}
                />
              </div>
            </div>
            <NavLink
              to={'/forgot-password'}
              className="block w-max underline text-sm ml-auto mr-2 mt-4 pt-0.5 lg:mt-1.5 lg:mr-0 lg:text-xs 2xl:text-sm"
            >
              Forgot password
            </NavLink>
            <div>
              <button
                type="submit"
                className="group text-xl relative w-full flex justify-center py-2 px-4 border border-transparent font-medium rounded-md text-white btn-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Login;
